<template>
  <div>   <div class="container pt-3 flex flex-row justify-content-center"  >
        <div class="row">
    <img class="img5" src="/404.png">
  </div>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.img5{
    width: 700px;
    height: auto;
  
   
}
</style>