<template>
    <div>
   
 <megamenu></megamenu>
   <blank></blank>
  
      <pphfooter></pphfooter>
    </div>
  </template>
  <script>

  import megamenu from "../components/pphmegamenu.vue";

  import blank from "../components/404page.vue";
  import pphfooter from "../components/pph_footer.vue";

  export default {
    name: "PPHPphHome",
    
    components: {
      megamenu,
   
      blank,
      pphfooter,
    }
}
   
  </script>
  <style></style>
  